<template>
  <div class="draftLineWrap" :class="($store.state.activeItems && $store.state.activeItems.ConfigCountryConfigs && $store.state.activeItems.ConfigCountryConfigs.id === draft.id) ? 'active' : ''" >
    <div class="lineitem first l-checkbox">
      <div class="checkbox">
        <input type="checkbox"
               :id="'id_'+draft.id"
               :value="draft.id"
               v-if="draftList && draftList.selected"
               v-model="draftList.selected[draft.id]"/>
        <label :for="'id_'+draft.id"></label>
      </div>
    </div>
    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.lang_name }}
    </div>

    <div class="lineitem l-default">
      {{ draft.lang_code }}
    </div>
    <div class="lineitem l-default ">
      <span tooltip="In Benutzung" position="left">
      <template v-if="draft.state==='yes'">
        <i class="fa-duotone fa-check-circle" style="color: #3B8130"></i>
      </template>
      <template v-else>
        <i class="fa-duotone fa-xmark-circle" style="color: #b21f28"></i>
      </template>
      </span>
    </div>
    <div class="lineitem l-colorcountry " :style="'background-color:'+draft.color + '; color: #fff;'">
      {{ draft.color }}
    </div>
    <div class="lineitem l-default ">
      <span tooltip="EU-Mitglied" position="left">
      <template v-if="draft.lang_in_eu==='yes'">
        <i class="fa-duotone fa-square-check" style="color: #3B8130" ></i>
      </template>
      <template v-else>
        <i class="fa-duotone fa-square-xmark" style="color: #b21f28"></i>
      </template>
      </span>
    </div>


    <div class="lineitem l-default">
      {{ draft.tax }}
    </div>

    <div class="lineitem l-default ml-auto">
      <template v-if="draft.default==='1'" >
        <i class="fa-duotone fa-check-circle"></i>
      </template>
    </div>



    <div class="lineitem last">
      <template v-if="$store.state.activeItems && $store.state.activeItems.ConfigCountryConfigs && $store.state.activeItems.ConfigCountryConfigs.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigCountryConfigs');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import $ from "jquery";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {
    ...mapGetters(["getEmployees", "draftList", "draftActive", "getConfigs"])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigCountryConfigs'};
      this.$store.dispatch("setActiveConfig",  newData).then(() => {
        this.$router.push({name: 'ConfigCountryConfigsDetails', params: {id: draft.id}})
      });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  }
}
</script>
