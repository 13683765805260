import {createRouter, createWebHashHistory} from "vue-router";

import Signin from "../routing/core/Login/Signin.vue";

import Document from "../views/Document.vue";

import EmployeeDetails from "@/routing/core/Employees/Details.vue";
import TimesOverview from "@/routing/core/Times/TimesOverview";
// import BankAccounts from "@/views/components/config/BankAccounts";
// import CostNumbers from "@/views/components/config/CostNumbers";
// import CustomerTaxes from "@/views/components/config/CustomerTaxes";
// import CustomerAcquisitions from "@/views/components/config/CustomerAcquisitions";
// import CustomerState from "@/views/components/config/CustomerState";
// import CustomerPrios from "@/views/components/config/CustomerPrios";
// import WorkingDays from "@/views/components/config/WorkingDays";
// import CustomerTypes from "@/views/components/config/CustomerTypes";
// import TimeConfigs from "@/views/components/config/TimeConfigs";





import OposOverview from "@/views/OposOverview";

/** new file structure begins here
 * /routing/core/ ... contains all routes from core modules
 * /routing/filter/ ... contains all filter modules
 * /routing/plugins/ ... contains all plugin modules
 */

// core imports
import store from "../store/index"
import Subscriptions from "@/routing/core/Subscriptions/Subscriptions";
import SubDetails from "@/routing/core/Subscriptions/Details";
import EmployeesOverview from "../routing/core/Employees/List.vue";
import StatsOverview from "@/routing/core/Stats/Stats.vue";
import ConfigGlobal from "@/routing/core/Config/global";
import ConfigOverviewBasic from "@/routing/core/Config/Basic.vue";
import ConfigOverviewlanding from "@/routing/core/Config/Basic.vue";
import ConfigOverviewAccounting from "@/routing/core/Config/Accounting.vue";
import ConfigOverviewEmployee from "@/routing/core/Config/Employee.vue";
import Customers from "@/routing/core/Customers/Customers.vue";
import Dashboard from "@/routing/core/Dashboard/Dashboard.vue";
import Products from "@/routing/core/Products/Products.vue";
import ActivitiesOverview from "@/routing/core/Activities/ActivitiesOverview.vue";
import Drafts from "@/routing/core/Drafts/Drafts.vue";
import DraftsDetails from "@/routing/core/Drafts/Details.vue";
import Tasks from "@/routing/core/Tasks/Tasks.vue";
import Accounting from "@/routing/core/Accounting/Accounting.vue";
import LegalOverview from "@/routing/core/Legals/Legals.vue";
import SEPAOverview from "@/routing/core/Sepa/Sepas.vue";
import Documents from "@/routing/core/Documents/Documents.vue";
import Files from "@/routing/core/Files/Files.vue";
import Events from "@/routing/core/Events/Events.vue";
import BankAccounts from "@/routing/core/Config/Accounting/BankAccounts/BankAccounts.vue";
import AccountingKeys from "@/routing/core/Config/Accounting/AccountingKeys/AccountingKeys.vue";
import CostNumbers from "@/routing/core/Config/Accounting/CostNumbers/CostNumbers.vue";
import CustomerTaxes from "@/routing/core/Config/Accounting/CustomerTaxes/CustomerTaxes.vue";
import CustomerAcquisitions from "@/routing/core/Config/Basic/CustomerAcquisitions/CustomerAcquisitions.vue";
import CustomerAcquisitionsDetails from "@/routing/core/Config/Basic/CustomerAcquisitions/Details.vue";
import CustomerState from "@/routing/core/Config/Basic/CustomerState/CustomerState.vue";
import CustomerStateDetail from "@/routing/core/Config/Basic/CustomerState/Details.vue";
import CustomerPrio from "@/routing/core/Config/Basic/CustomerPrio/CustomerPrio.vue";
import CustomerType from "@/routing/core/Config/Basic/CustomerType/CustomerType.vue";
import CustomerTags from "@/routing/core/Config/Basic/CustomerTags/CustomerTags.vue";
import CustomerTitle from "@/routing/core/Config/Basic/CustomerTitle/CustomerTitle.vue";
import CountryConfigs from "@/routing/core/Config/Basic/CountryConfigs/CountryConfigs.vue";
import TimeConfigs from "@/routing/core/Config/Employee/TimeConfigs/TimeConfigs.vue";
import ConfigOverviewWorkflow from "@/routing/core/Config/Workflow.vue";
import ConfigWorkflowTracker from "@/routing/core/Config/Workflow/IssueTracker/IssueTracker.vue";
import ConfigWorkflowActivities from "@/routing/core/Config/Workflow/IssueActivities/IssueActivities.vue";
import ConfigWorkflowStates from "@/routing/core/Config/Workflow/IssueStates/IssueStates.vue";
import ConfigWorkflowPrio from "@/routing/core/Config/Workflow/IssuePrio/IssuePrio.vue";
import ConfigWorkflowGlobal from "@/routing/core/Config/Workflow/global.vue";
import Queue from "@/routing/core/Queue/Queue.vue";
import CategoriesOverview from "@/routing/core/Categories/Categories.vue";
import CategoriesDetails from "@/routing/core/Categories/Details.vue";
// filter imports
import FilterAccountingKeys from "@/filter/AccountingKeys";
import FilterCustomerAcquisitions from "@/filter/CustomerAcquisitions";
import FilterCustomerState from "@/filter/CustomerState";
import FilterCustomerPrio from "@/filter/CustomerPrio";
import FilterCustomerType from "@/filter/CustomerType";
import FilterCustomerTags from "@/filter/CustomerTags";
import FilterCustomerTitle from "@/filter/CustomerTitle";
import FilterTimeConfigs from "@/filter/TimeConfigs";
import FilterCountryConfigs from "@/filter/CountryConfigs";
import FilterBankAccounts from "@/filter/BankAccounts";
import FilterCostNumbers from "@/filter/CostNumbers";
import FilterCustomerTaxes from "@/filter/CustomerTaxes";
import FilterCustomers from "@/filter/Customers";
import FilterDocuments from "@/filter/Documents";
import FilterProducts from "@/filter/Products";
import FilterTasks from "@/filter/Tasks";
import FilterDrafts from "@/filter/Drafts";
import FilterEvents from "@/filter/Events";


// plugin imports
import DFBDashboard from '@/routing/plugins/dfb/Dashboard'
import DFBCalcTypes from '@/routing/plugins/dfb/subs/CalcTypes'


const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/accounting",
    name: "Accounting",
    component: Accounting,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    children: [
      {
        path: '/signin/:resetId/:resetToken',
        name: "SigninReset",
        component: Signin,
      }
    ]
  },
  {
    path: "/files",
    name: "Files",
    component: Files,
  },
  {
    path: "/config/landing",
    name: "ConfigLanding",
    component: ConfigOverviewlanding,

  },
  {
    path: '/config/accounting',
    name: 'ConfigAccounting',
    component: ConfigOverviewAccounting,
  },


  {
    path: "/config/accounting/keys",
    name: "ConfigAccountingKeys",
    components: {
      default: AccountingKeys,
      filter: FilterAccountingKeys
    },
    children: [
      {
        path: '/config/accounting/keys/:id',
        name: "ConfigAccountingKeysDetails",
        component: AccountingKeys,
      }
    ]
  },
  {
    path: "/config/accounting/bankaccounts",
    name: "ConfigBankAccounts",
    components: {
      default: BankAccounts,
      filter: FilterBankAccounts
    },
    children: [
      {
        path: '/config/accounting/bankaccounts/:id',
        name: "ConfigBankAccountsDetails",
        component: BankAccounts,
      }
    ]
  },

  {
    path: "/config/accounting/costnumbers",
    name: "ConfigCostNumbers",
    components: {
      default: CostNumbers,
      filter: FilterCostNumbers
    },
    children: [
      {
        path: '/config/accounting/costnumbers/:id',
        name: "ConfigCostNumbersDetails",
        component: CostNumbers,
      }
    ]
  },

  {
    path: "/config/accounting/customertaxes",
    name: "ConfigCustomerTaxes",
    components: {
      default: CustomerTaxes,
      filter: FilterCustomerTaxes
    },
    children: [
      {
        path: '/config/accounting/customertaxes/:id',
        name: "ConfigCustomerTaxesDetails",
        component: CustomerTaxes,
      }
    ]
  },

  {
    path: "/config/basic/customeracquisitions",
    name: "ConfigCustomerAcquisitions",
    components: {
      default: CustomerAcquisitions,
      filter: FilterCustomerAcquisitions,
    },
      children: [
        {
          path: ":id",
          name: "ConfigCustomerAcquisitionsDetails",
          component: CustomerAcquisitionsDetails, // Use a separate component for the child route
        }
      ]
    },


  {
    path: "/config/basic/customerstate",
    name: "ConfigCustomerState",
    components: {
      default: CustomerState,
      filter: FilterCustomerState,
    },
    children: [
      {
        path: ":id",
        name: "ConfigCustomerStateDetails",
        component: CustomerStateDetail, // Use a separate component for the child route
      }
    ]
  },

  {
    path: "/config/basic/customerprio",
    name: "ConfigCustomerPrio",
    components: {
      default: CustomerPrio,
      filter: FilterCustomerPrio
    },
      children: [
        {
          path: '/config/basic/customerprio/:id',
          name: "ConfigCustomerPrioDetails",
          component: CustomerPrio,
        }
      ]
  },

  {
    path: "/config/basic/customertype",
    name: "ConfigCustomerType",
    components: {
      default: CustomerType,
      filter: FilterCustomerType,
    },
      children: [
        {
          path: '/config/basic/customertype/:id',
          name: "ConfigCustomerTypeDetails",
          component: CustomerType,
        }
      ]
  },

  {
    path: "/config/basic/customertags",
    name: "ConfigCustomerTags",
    components: {
      default: CustomerTags,
      filter: FilterCustomerTags
    },
    children: [
      {
        path: '/config/accounting/customertags/:id',
        name: "ConfigCustomerTagsDetails",
        component: CustomerTags,
      }
    ]
  },

  {
    path: "/config/basic/customertitle",
    name: "ConfigCustomerTitle",
    components: {
      default: CustomerTitle,
      filter: FilterCustomerTitle
    },
    children: [
      {
        path: '/config/accounting/customertitle/:id',
        name: "ConfigCustomerTitleDetails",
        component: CustomerTitle,
      }
    ]
  },

  {
    path: "/config/basic/countryconfigs",
    name: "ConfigCountryConfigs",
    components: {
      default: CountryConfigs,
      filter: FilterCountryConfigs
    },
    children: [
      {
        path: '/config/accounting/countryconfigs/:id',
        name: "ConfigCountryConfigsDetails",
        component: CountryConfigs,
      }
    ]
  },

  {
    path: "/config/employee/timeconfigs",
    name: "ConfigTimeConfigs",
    components: {
      default: TimeConfigs,
      filter: FilterTimeConfigs
    },
    children: [
      {
        path: '/config/employee/timesconfigs/:id',
        name: "ConfigTimeConfigsDetails",
        component: TimeConfigs,
      }
    ]
  },

  {
    path: '/config/global',
    name: 'ConfigGlobal',
    component: ConfigGlobal,

  },
  {
    path: '/config/employee',
    name: 'ConfigEmployee',
    component: ConfigOverviewEmployee,

  },
  {
    path: '/config/basic',
    name: 'ConfigBasic',
    component: ConfigOverviewBasic,
  },
  {
    path: "/dfb",
    name: "DFB",
    component: DFBDashboard,
    children: [
      {
        path: '/dfb/calcTypes',
        name: 'DFBCalcTypes',
        component: DFBCalcTypes,
      }
    ]
  },


  {
    path: "/customers",
    name: "customers",
    components: {
      default: Customers,
      filter: FilterCustomers
    },
    children: [
      {
        path: '/customers/:id',
        name: 'CustomersDetails',
        component: Customers,
        children: [
          {
            path: '/customers/:id/:tab',
            name: 'CustomersTabs',
            component: Customers,
            children: [{
              path: '/customers/:id/:tab/:subtab',
              name: 'CustomersTabsSubtabs',
              component: Customers,
            }]
          }
        ]
      },

    ]
  },
  {
    path: "/categories",
    name: "categories",
    components: {default: CategoriesOverview},
    children: [
      {
        path: '/categories/:id',
        name: 'CategoriesDetails',
        component: CategoriesDetails,
      }
    ]
  },
  {
    path: "/documents",
    name: "Documents",
    components: {default: Documents, filter: FilterDocuments},
    children: [
      {
        path: '/documents/:id',
        name: 'DocumentsDetails',
        component: Documents,
        children: [
          {
            path: '/documents/:id/:tab',
            name: 'DocumentsTabs',
            component: Documents,
            children: [{
              path: '/documents/:id/:tab/:subtab',
              name: 'DocumentsTabsSubtabs',
              component: Documents,
            }]
          }
        ]
      }
    ]

  },
  {
    path: "/products",
    name: "products",
    components: {default: Products, filter: FilterProducts},
    children: [
      {
        path: '/product/:id',
        name: 'ProductDetails',
        component: Products,
      }
    ]
  },
  {
    path: "/employees",
    name: "employees",
    component: EmployeesOverview,
    children: [
      {
        path: '/employees/new',
        name: 'EmployeeOverview',
        component: EmployeesOverview,
      },
        {
        path: '/employee/:id',
        name: 'EmployeeDetails',
        component: EmployeeDetails,
      }
    ]
  },
  {
    path: "/document/:docId",
    name: "Document",
    component: Document,
  },
  {
    path: "/times",
    name: "TimesOverview",
    component: TimesOverview,
  },
  {
    path: "/config/global",
    name: "ConfigGlobal",
    component: ConfigGlobal,
  },
  {
    path: '/config/workflow',
    name: 'ConfigWorkflow',
    component: ConfigOverviewWorkflow,
  },
  {
    path: '/config/workflow/global',
    name: 'ConfigWorkflowGlobal',
    component: ConfigWorkflowGlobal
  },
  {
    path: '/config/workflow/tracker',
    name: 'ConfigWorkflowTracker',
    component: ConfigWorkflowTracker,
    children: [
      {
        path: '/config/workflow/tracker/:id',
        name: 'ConfigWorkflowTrackerDetails',
        component: ConfigWorkflowTracker,
      }
    ]
  },
  {
    path: '/config/workflow/activities',
    name: 'ConfigWorkflowActivities',
    component: ConfigWorkflowActivities,
    children: [
      {
        path: '/config/workflow/activities/:id',
        name: 'ConfigWorkflowActivitiesDetails',
        component: ConfigWorkflowActivities,
      }
    ]
  },
  {
    path: '/config/workflow/prio',
    name: 'ConfigWorkflowPrio',
    component: ConfigWorkflowPrio,
    children: [
      {
        path: '/config/workflow/prio/:id',
        name: 'ConfigWorkflowPrioDetails',
        component: ConfigWorkflowPrio,
      }
    ]
  },
  {
    path: '/config/workflow/states',
    name: 'ConfigWorkflowStates',
    component: ConfigWorkflowStates,
    children: [
      {
        path: '/config/workflow/states/:id',
        name: 'ConfigWorkflowStatesDetails',
        component: ConfigWorkflowStates,
      }
    ]
  },

  // {
  //   path: "/config/bankaccounts",
  //   name: "BankAccounts",
  //   component: BankAccounts,
  // },
  // {
  //   path: "/config/accountingkeys",
  //   name: "AccountingKeys",
  //   component: AccountingKeys,
  // },
  // {
  //   path: "/config/costnumbers",
  //   name: "CostNumbers",
  //   component: CostNumbers,
  // },
  // {
  //   path: "/config/customertaxes",
  //   name: "CustomerTaxes",
  //   component: CustomerTaxes,
  // },
  // {
  // path: "/config/customeracquisitions",
  // name: "CustomerAcquisitions",
  // component: CustomerAcquisitions,
  // },
  // {
  //   path: "/config/customerstates",
  //   name: "CustomerState",
  //   component: CustomerState,
  // },
  // {
  //   path: "/config/customerprios",
  //   name: "CustomerPrios",
  //   component: CustomerPrios,
  // },
  // {
  //   path: "/config/workingdays",
  //   name: "WorkingDays",
  //   component: WorkingDays,
  // },
  // {
  //   path: "/config/customertypes",
  //   name: "CustomerTypes",
  //   component: CustomerTypes,
  // },
  // {
  //   path: "/config/customertags",
  //   name: "CustomerTags",
  //   component: CustomerTags,
  // },
  // {
  //   path: "/config/countries",
  //   name: "CountryConfigs",
  //   component: CountryConfigs,
  // },
  {
    path: "/abonnements",
    name: "Subscriptions",
    component: Subscriptions,
    children: [
      {
        path: '/abonnements/:id',
        name: 'Details',
        component: SubDetails,
      }
    ]
  },
  {
    path: "/activities",
    name: "ActivitiesOverview",
    component: ActivitiesOverview,
  },
  {
    path: "/queue",
    name: "Queue",
    component: Queue,
  },
  {
    path: "/sepa",
    name: "Sepa",
    component: SEPAOverview,
  },
  {
    path: "/opos",
    name: "OposOverview",
    component: OposOverview,
  },
  {
    path: "/stats",
    name: "StatsOverview",
    component: StatsOverview,
    children: [
      {
        path: '/stats/:type',
        name: 'StatsType',
        component: StatsOverview,

      }
    ]
  },
  {
    path: "/legal",
    name: "Legals",
    component: LegalOverview,
  },

  {
    path: "/tasks",
    name: "Tasks",
    components: {default: Tasks, filter: FilterTasks},
    children: [
      {
        path: '/tasks/:id',
        name: 'TaskDetails',
        component: Tasks,
        children: [{
          path: '/tasks/:id/:tab',
          name: 'TasksTabs',
          component: Tasks,
        }]
      }
    ]
  },
  {
    path: "/events",
    name: "Events",
    components: {default: Events, filter: FilterEvents},
    children: [
      {
        path: '/events/:id',
        name: 'EventDetails',
        component: Events,
        children: [{
          path: '/events/:id/:tab',
          name: 'EventTabs',
          component: Events,
        }]
      }
    ]
  },
  {
    path: "/drafts",
    name: "drafts",
    components: {default: Drafts, filter: FilterDrafts},
    children: [
      {
        path: '/draft/:id',
        name: 'DraftDetails',
        component: DraftsDetails,
      },
    ]
  },
  {path: '/:catchAll(.*)', component: Dashboard}
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach( (to, from, next) => {
  store.commit("forceSWupdate");

  if (store.state.scope === undefined) {

     store.dispatch("checkToken").then(function () {

      if ((store.state.scope !== 'employee' && (store.state.user === undefined || !store.state.user._isEmployee) && store.state.scope !== 'joomla-superuser') && to.name !== 'Signin' && to.name !== 'SigninReset') {

        router.push({name: 'Signin'});
      } else {
        next();
      }
    });
  } else {
    if ((store.state.scope !== 'employee' && (store.state.user === undefined || !store.state.user._isEmployee) && store.state.scope !== 'joomla-superuser') && to.name !== 'Signin' && to.name !== 'SigninReset') {

      router.push({name: 'Signin'});
    } else {
      next();
    }
  }


});
export default router;
