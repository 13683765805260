<template>

  <div class="mailWrapper">
    <template v-if="customerActive">E-Mail geht an: {{customerActive.c_email}}</template>

<!--          <div class="row">-->
<!--            <div class="col-4">-->
<!--              <button type="button" class="btn btn-outline-primary w-100"-->
<!--                      :class="(activeMethod === 'mail') ? 'btn-primary' : ''"-->
<!--                      @click="activeMethod = 'mail'; ">{{$t("globals.email")}}-->
<!--              </button>-->
<!--            </div>-->
<!--            <div class="col-4">-->
<!--              <button type="button" class="btn btn-outline-primary w-100"-->
<!--                      :class="(activeMethod === 'attachment') ? 'btn-primary' : ''"-->
<!--                      @click="activeMethod = 'attachment'">{{$t("documents.templateAttachment")}}-->
<!--              </button>-->
<!--            </div>-->
<!--            <div class="col-4">-->
<!--              <button type="button" class="btn btn-outline-primary w-100"-->
<!--                      :class="(activeMethod === 'pdf') ? 'btn-primary' : ''" @click="activeMethod = 'pdf'; ">-->
<!--                {{$t("documents.onlyCreatePDF")}}-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
          <hr>
    <div class="row">
      <div class="col-12">
        <div class="form-group">

          <select style="width: 100%; margin-bottom: 24px;"
                  v-model="activeDraft"
          >
            <option :value="{draft: ''}">- Aus Vorlage wählen -</option>
            <optgroup :label="tit" v-for="(dr,tit) in activeDrafts">
              <option :value="draft" v-for="draft in dr" :key="draft.id">{{ draft.title }}</option>
            </optgroup>
          </select>
        </div>
      </div>
    </div>
          <div class="row mt-5">

            <div class="col-12">
              <div class="row" v-if="activeMethod === 'attachment'">
                <div class="col-12">
                  <div class="form-group">
                    <input type="text" class="form-control" :placeholder="$t('documents.emailSubject')"
                           v-model="mailer.separateSubject">
                  </div>
                  <div class="form-group">
                    <Editor2 v-model="mailer.separateContent" ></Editor2>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <input type="text" class="form-control" :placeholder="$t('documents.emailPDFSubject')"
                           v-model="mailer.subject">
                  </div>
                  <div class="form-group">
                    <Editor2 v-model="mailer.content" ></Editor2>
                  </div>
                </div>
              </div>
            </div>
          </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary w-100" @click="withTemplate = true"
                    :class="(withTemplate) ? 'btn-primary' : ''">
              {{$t("documents.withEmailTemplate")}}
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary w-100" @click="withTemplate = false"
                    :class="(withTemplate) ? '' : 'btn-primary'">
              {{$t("documents.withoutEmailTemplate")}}
            </button>
          </div>
        </div>
        <div class="row" v-if="activeMethod !== 'mail'">
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary w-100"
                    @click="pdfOptions.header = 'with'"
                    :class="(pdfOptions.header === 'with') ? 'btn-primary' : ''">
              {{$t("documents.withPDFHeader")}}
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary w-100"
                    @click="pdfOptions.header = 'without'"
                    :class="(pdfOptions.header === 'with') ? '' : 'btn-primary'">
              {{$t("documents.withoutPDFHeader")}}
            </button>
          </div>
        </div>
        <div class="row" v-if="activeMethod !== 'mail'">
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary w-100" @click="pdfOptions.bg = true"
                    :class="(pdfOptions.bg === true) ? 'btn-primary' : ''">
              {{$t("documents.withPDFBackground")}}
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-outline-primary w-100" @click="pdfOptions.bg = false"
                    :class="(pdfOptions.bg === true) ? '' : 'btn-primary'">
              {{$t("documents.withoutPDFBackground")}}
            </button>
          </div>
        </div>

      </div>
    </div>
          <div class="row mt-3">
            <div class="col-6">
              <button type="button" class="btn btn-outline-secondary" @click="$store.commit('closeModal', 'mail')"><i class="fa-duotone fa-xmark-circle"></i> {{$t("globals.cancel")}}</button>
            </div>
            <div class="col-6">
              <button type="button" @click="sendMail" class="btn btn-outline-primary float-end"><i class="fa-duotone fa-paper-plane"></i> {{$t("globals.send")}}</button>
            </div>
          </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";

export default {
  name: "Mail",
  components: {Editor2},
  props: {
    customer: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigs", "getPlugins", "customerActive", "draftList"]),
    drafts() {
      return this.$store.getters.getDrafts;
    }
  },
  data() {
    return {
      activeMethod: 'mail',
      activeSubtab: null,
      activeDrafts: {},
      activeDraft: {draft: ''},
      withTemplate: false,
      attachments: [],
      filledVars: [],
      orderTabs: [
        {name: 'base', label: this.$t('documents.baseSettings'), icon: 'fa-cog'},
        {name: 'booking', label: this.$t('documents.billingData'), icon: 'fa-coin'},
        {name: 'docs', label: this.$t('documents.documentDispatch'), icon: 'fa-envelope'},
      ],
      activeOrderTab: 'base',
      pdfOptions: {
        header: 'with',
        bg: true,
      },
      sent: null,
      sentError: null,
      showPdf: false,
      showOrder: false,
      showContract: false,
      mailer: {
        subject: '',
        separateSubject: '',
        content: '',
        separateContent: '',
      },
      drafts: [],
    }
  },
  methods: {
    getDrafts() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search?limit=0", {headers}).then((r) => {
        this.drafts = r.data.result.data;
        // sort drafts by group_id in activeDrafts
        this.drafts.forEach((draft) => {
          if (this.activeDrafts[draft.categoryTitle] === undefined) {
            this.activeDrafts[draft.categoryTitle] = [];
          }
          this.activeDrafts[draft.categoryTitle].push(draft);
        });
        this.loading = false;
      });
    },
    setDraft() {
      this.mailer.content = this.activeDraft.draft;
      this.mailer.separateContent = this.activeDraft.draft;
    },
    sendMail() {
      alert("TO implement");
      return;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const data = {
        customer: this.customer,
        mailer: this.mailer,
        pdfOptions: this.pdfOptions,
        attachments: this.attachments,
        withTemplate: this.withTemplate,
        filledVars: this.filledVars,
      };
      axios.post(this.$store.getters.getApiUrl + "sendMail", data, {headers}).then((r) => {
        this.sent = r.data.result;
        this.sentError = null;
        this.showPdf = false;
        this.showOrder = false;
        this.showContract = false;
        this.mailer = {
          subject: '',
          separateSubject: '',
          content: '',
          separateContent: '',
        };
        this.attachments = [];
        this.filledVars = [];
});
    },
  },

  created() {
    this.getDrafts();
  },
  watch: {
    activeDraft() {
      if (this.activeDraft) {
        if (this.activeMethod === 'attachment') {
          this.mailer.separateContent = this.activeDraft.draft;
          this.mailer.separateSubject = this.activeDraft.title;
        }
        else {
        this.mailer.content = this.activeDraft.draft;
        this.mailer.subject = this.activeDraft.title;
        }
      }
    },
  }

}

</script>
<style>
.mailWrapper {
  padding: 20px;
  background: #fff;
}
</style>
