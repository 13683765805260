<template>
  <div class="draftLineWrap" :class="($store.state.activeItems && $store.state.activeItems.ConfigWorkflowActivities && $store.state.activeItems.ConfigWorkflowActivities.id === draft.id) ? 'active' : ''" >
    <div class="lineitem first l-checkbox">
      <div class="checkbox">
        <input type="checkbox"
               :id="'id_'+draft.id"
               :value="draft.id"
               v-if="draftList && draftList.selected"
               v-model="draftList.selected[draft.id]"/>
        <label :for="'id_'+draft.id"></label>
      </div>
    </div>
    <div class="lineitem l-title" @click="setConfig(draft)">
      {{ draft.title }}
    </div>
    <div class="lineitem l-state" >
      {{ draft.state }}
    </div>
    <div class="lineitem l-default">
    <i class="fa-duotone" :class="'fa-'+draft.icon" :style="'color:'+draft.color"></i>
  </div>
    <div class="lineitem l-color" :style="'background-color:'+draft.color + '; color: #fff;'">
      {{ draft.color }}
    </div>

    <div class="lineitem l-number">
      {{ draft.ordering }}
    </div>
    <div class="lineitem last ml-auto">
      <template v-if="$store.state.activeItems && $store.state.activeItems.ConfigWorkflowActivities && $store.state.activeItems.ConfigWorkflowActivities.id === draft.id">
        <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeConfigWorkflowActivities');"></i>
      </template>
      <template v-else><i class="fa-duotone fa-chevrons-up"  @click="setConfig(draft)"></i></template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Headline",
  props: {
    draft: Object,
  },
  computed: {

    ...mapGetters([ "getEmployees" , "getConfigWorkflowActivities"])
  },
  methods: {
    setConfig(draft) {
      const newData = {data: draft, type:'ConfigWorkflowActivities'};
      this.$store.dispatch("setActiveConfig",  newData).then(() => {
        this.$router.push({name: 'ConfigWorkflowActivitiesDetails', params: {id: draft.id}})
      });


    },
  }
}
</script>
