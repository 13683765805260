<template>
{{c}}
  <div class="card edit-customer-card">
    <h6 class="card-header">

      <i class="fa-duotone fa-pen"></i>&nbsp;
      <template v-if="c & c.id">
        {{$t("edit.editCustomer")}}
      </template>
      <template v-else>{{$t("edit.newCustomer")}}</template>
      <div class="btn-group float-end">
        <button class="btn btn-outline-primary btn-sm" @click="updateCustomer()"><i class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}</button>

        <button class="btn btn-outline-secondary btn-sm " @click="cancelEdit()"><i class="fa-duotone fa-xmark-circle"></i> {{$t("globals.cancel")}}</button>
      </div>
    </h6>
    <div class="card-body">
      <ul class="tabNav subtabs">
        <li   :class="(activeTab === 'customersettings') ? 'active' : ''"
              @click="activeTab = 'customersettings';"><i class="fa-duotone fa-user-chart"></i> {{$t("edit.customerData")}}
        </li>
        <li  :class="(activeTab === 'basicsettings') ? 'active' : ''"
              @click="activeTab = 'basicsettings';"><i class="fa-duotone fa-user-cog"></i> {{$t("documents.baseSettings")}}</li>
        <li  :class="(activeTab === 'paymentsettings') ? 'active' : ''"
              @click="activeTab = 'paymentsettings';"><i class="fa-duotone fa-file-contract"></i> {{$t("documents.billingData")}}</li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active pt-3" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
             tabindex="0" v-if="activeTab === 'customersettings'">
          <div class="row mb-3">
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{$t("globals.customerNumber")}}</label>
                <input class="form-control" v-model="c.c_customer_id">
              </div>
            </div>
            <div class="col-12 col-lg-9">
              <div class="form-group">
                <label>{{$t("globals.company")}}</label>
                <input class="form-control" v-model="c.c_company">
              </div>
            </div>
          </div>
          <h5>{{$t("globals.contactData")}}</h5>
          <div class="row">
            <div class="col-12 col-lg-2">
              <div class="form-group">
                <label>{{$t("globals.salutation")}}</label>
                <input class="form-control" v-model="c.c_title">
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div class="form-group">
                <label>Namenszusatz</label>
                <input class="form-control" v-model="c.c_suffix">
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div class="form-group">
                <label>{{$t("globals.firstName")}}</label>
                <input class="form-control" v-model="c.c_firstname">
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div class="form-group">
                <label>{{$t("globals.surname")}}</label>
                <input class="form-control" v-model="c.c_lastname">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="form-group">
                <label>{{$t("globals.email")}}</label>
                <input class="form-control" v-model="c.c_email">
              </div>
            </div>
            </div>
          <div class="row">
            <div class="col-12 col-lg-6 col-xl-3">
              <div class="form-group">
                <label>{{$t("globals.state")}}</label>
                <multiselect v-model="localCountry"  :id="'country'"
                                :modelValue="'id'"
                             :custom-label="nameWithLang"
                                :valueProp="'id'"
                                :options="getConfigs.countries"
                                :multiple="false"
                                :label="'lang_code'"
                                :closeOnSelect="true"
                                :searchable="true"
                             :allow-empty="true"
                                :clearOnSelect="false"
                                :showLabels="false"
                                :placeholder="'Land wählen'"
                                :trackBy="'id'"
                                @update:model-value="updateSelected"
                ></multiselect>
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3">
              <div class="form-group">
                <label>{{$t("edit.streetPOBox")}}</label>
                <input class="form-control" v-model="c.c_street">
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-2">
              <div class="form-group">
                <label>{{$t("globals.zipCode")}}</label>
                <input class="form-control" v-model="c.c_zipcode">
              </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
              <div class="form-group">
                <label>{{$t("globals.location")}}</label>
                <input class="form-control" v-model="c.c_city">
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{$t("globals.phone")}}</label>
                <input class="form-control" v-model="c.c_phone">
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>Telefon geschäftlich</label>
                <input class="form-control" v-model="c.c_phone2">
              </div>
            </div>
            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{$t("globals.mobilePhone")}}</label>
                <input class="form-control" v-model="c.c_handy">
              </div>
            </div>

            <div class="col-12 col-lg-3">
              <div class="form-group">
                <label>{{$t("globals.faxMachine")}}</label>
                <input class="form-control" v-model="c.c_fax">
              </div>
            </div>
          </div>
          <h5>{{$t("edit.moreInfos")}}</h5>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>{{$t("globals.business")}}</label>
                <input class="form-control" v-model="c.c_branch">
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>{{$t("globals.companyForm")}}</label>
                <input class="form-control" v-model="c.c_business">
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>{{$t("globals.website")}}</label>
                <input class="form-control" v-model="c.c_website">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>Funktion</label>
                <input class="form-control" v-model="c.funktion">
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>Geschlecht</label>
                <input class="form-control" v-model="c.c_gender">
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>{{$t("globals.dateOfBirth")}}</label>
                <VueDatePicker v-model="c.c_birthday" model-type="yyyy-MM-dd" format="dd.MM.yyyy" :placeholder="$t('globals.selectDateOfBirth')"  :auto-apply="true" :close-on-auto-apply="true" locale="de"    :enable-time-picker="false" cancel-text="X"  :select-text="this.$t('glboals.choose')"  :teleport="true"   >

                </VueDatePicker>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label>{{$t("globals.placeOfBirth")}}</label>
                <input class="form-control" v-model="c.birthday_city"  >
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show active pt-3" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
             tabindex="0" v-if="activeTab === 'basicsettings'">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.customerCategory")}}</label>
                <select  class="form-select" v-model="c.c_catid">
                  <option :value="customerCategory.id" v-for="customerCategory in getConfigs.categories_customers" :key="customerCategory.id">
                    {{ customerCategory.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.customerStatus")}}</label>
                <select  class="form-select" v-model="c.c_state">
                  <option :value="customerState.id" v-for="customerState in getConfigs.customerState" :key="customerState.id">
                    {{ customerState.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.accountManager")}}</label>
                <select  class="form-select" v-model="c.c_owner">
                  <option :value="employee.id" v-for="employee in getEmployees" :key="employee.id">{{
                      employee.e_name
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.customerPriority")}}</label>
                <select  class="form-select" v-model="c.c_prio">
                  <option :value="prio.id" v-for="prio in getConfigs.customerPrio" :key="prio.id">{{ prio.name }}</option>

                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.acquisitionStatus")}}</label>
                <select  class="form-select" v-model="c.c_akquise">
                  <option :value="ca.id" v-for="ca in getConfigs.customerAcquisition" :key="ca.id">
                    {{ ca.name }}
                  </option>

                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.customerType")}}</label>
                <select  class="form-select" v-model="c.c_type">
                  <option :value="customerType.id" v-for="customerType in getConfigs.customerTypes" :key="customerType.id">
                    {{ customerType.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.billingLanguage")}}</label>
                <select  class="form-select" v-model="c.invoice_lang">
                  <option value="de">{{$t("globals.german")}}</option>
                  <option value="en">{{$t("globals.english")}}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("globals.tags")}}</label>
                <multiselect v-model="c.tags" :id="'tags'"
                             :modelValue="'id'"
                             :valueProp="'id'"
                             :options="getConfigs.customerTags"
                             :multiple="true"
                             :label="'name'"
                             :closeOnSelect="false"
                             :allow-empty="true"
                             :clearOnSelect="false"
                             :showLabels="false"
                             :taggable="true"
                             @tag="addTag"
                             :placeholder="$t('globals.chooseTags')"
                             :tag-placeholder="$t('globals.insertNewTag')"
                             :trackBy="'id'"
                             @update:model-value="updateSelected"
                ></multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show active pt-3" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
             tabindex="0" v-if="activeTab === 'paymentsettings'">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.defaultInvoiceType")}}</label>
                <select  class="form-select" v-model="c.invoice_type">
                  <option value="1">{{$t("edit.paymentMethod.default")}}</option>
                  <option value="2">{{$t("edit.paymentMethod.separateDirectDebit")}}</option>
                  <option value="3">{{$t("edit.paymentMethod.discount")}}</option>
                  <option value="5">{{$t("edit.paymentMethod.prePay")}}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.salesTaxId")}}</label>
                <input class="form-control" v-model="c.c_ustid">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.invoiceRecipient")}}</label>
                <input class="form-control" v-model="c.invoice_mail">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.taxRegime")}}</label>
                <select  class="form-select" v-model="c.c_tax_rule">
                  <option :value="tax.id" v-for="tax in getConfigs.customerTax" v-bind:key="tax.id">{{
                      tax.rule_name
                    }}
                  </option>
                </select>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.vendorNumber")}}</label>
                <input class="form-control" v-model="c.reseller_id">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.discountPercent")}}</label>
                <input class="form-control" type="number" v-model="c.c_discount">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.vendorAccounting")}}</label>
                <input class="form-control" type="number" v-model="c.account_number">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.defaultBehaviorAccount")}}</label>
                <input class="form-control" type="number" v-model="c.account_outlay">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.defaultBehaviorBookingKeys")}}</label>
                <input class="form-control" type="number" v-model="c.account_key">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.defaultBehaviorCostPosition")}}</label>
                <input class="form-control" type="number" v-model="c.account_cost">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.projectIdCommaSeparated")}}</label>
                <input class="form-control" v-model="c.project_ids">
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.freeMonthlyQuota")}}</label>
                <input class="form-control" type="number" v-model="c.project_free_hours_monthly">
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.netSalesStartAmount")}}</label>
                <input class="form-control" type="number" v-model="c.account_startsum_netto">
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label>{{$t("edit.grossSalesStartAmount")}}</label>
                <input class="form-control" type="number" v-model="c.account_startsum_brutto">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--{{ customer }}-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import indexedDbFunctions from "@/assets/js/indexedDbFunctions";
import {useToast} from "vue-toastification";
export default {
  name: 'EditCustomer',
emits: ['close'],
  data() {
    return {
      c: { },
      localCountry: null,
      toast: {
        class: 'bg-info',
        message: this.$t("edit.processingData"),
      },
      activeTab: 'customersettings'
    }
  },
  props:{
    comefrom: String
  },
components: {
    VueDatePicker
  },
  computed: {
    ...mapGetters(["getPlugins", "getEmployees", "customerActive", "getConfigs"])
  },
  methods: {
    nameWithLang ({ lang_code, lang_name }) {
      return `${lang_code} - ${lang_name}`
    },

    cancelEdit(){
      if(this.comefrom === 'documents'){
        this.$emit('close');
      } else {
        window.history.back();
        this.$store.commit("closeModal", "customer")
      }

    },
    updateSelected(){
      this.c.c_country = this.localCountry.id;
    },
    addTag (newTag) {
      // random id
      this.c.tags.push({name:newTag, code:Math.random().toString(36).substr(2, 9)})
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    createCustomer() {

      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      //const headers = {};
      axios.post(this.$store.state.apiUrl + "customer/", this.c, {headers}).then(() => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.customerCreated"));
        this.$store.commit("closeModal", "customer");
        this.$store.dispatch('customersFilter');
        // reset active customer
        this.$store.commit('setActiveItem',{type:'customer',data: {}});
      }).catch((e) => {
        //this.$store.commit("handleApiResponse", e.response);

      });
    },
    updateCustomer() {
      if(!this.c.id){
        this.createCustomer();
        return;
      }
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "customer/" + this.c.id, this.c, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        if(this.comefrom === 'documents'){
          this.$emit('close');
        } else {
          this.$store.dispatch('customersFilter');
          this.$store.commit('setActiveItem',{type:'customer',data: r.data.result.data});
        this.$router.push({name: "CustomersDetails", params: {id: r.data.result.data.id}});}
      }).catch((e) => {

        this.$store.commit("handleApiResponse", e.response);
      });
    }
  },
  watch: {
    customerActive: function () {
      this.c = this.customerActive;
    }
  },
  created() {
    this.c = this.customerActive;
    if (!this.c.id) {
      this.localCountry = this.getConfigs.countries.find((country) => country.default === '1');
      // Prefill selects
      this.c.c_catid = this.getConfigs.categories_customers[0].id;
      this.c.c_state = this.getConfigs.customerState[0].id;
      this.c.c_owner = this.getEmployees[0].id;
      this.c._prio_id = this.getConfigs.customerPrio[0].id;
      this.c.c_akquise = this.getConfigs.customerAcquisition[0].id;
      this.c.c_type = this.getConfigs.customerTypes[0].id;
      this.c.invoice_lang = 'de';
      this.c.c_tax_rule = this.getConfigs.customerTax[0].id;
      this.c.invoice_type = "1";


    }
    else {
      this.localCountry = this.getConfigs.countries.find((country) => country.id === this.c.c_country);
    }

  },

}
</script>
<style lang="scss">
.nav-pills {
  .nav-item {
    padding: 0 1rem 0 1rem;
    .nav-link {
      border: .125rem solid #5e72e4 !important;
      &.active {
        background-color: #5e72e4 !important;
        color: #fff !important;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
</style>
