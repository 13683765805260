<template>
  <div class="appView config purpleView">
    <div class="productItem activeProduct" >
      <div class="card">
        <h6 class="card-header ">Globale Einstellungen
          <div class="btn-group float-end">
          <button @click="saveConfig"
                  type="button" class=" btn btn-sm btn-outline-primary">
            <i class="fa-duotone fa-floppy-disk"></i> Speichern
          </button></div>


        </h6>
        <ul class="tabNav">
          <li v-for="cat in configTabs" :key="cat.name"
              @click="changeTab(cat.name)"
              :class="(activeSubtab === cat.name) ? 'active' : ''"><i class="fa-duotone" :class="cat.icon"></i><br><small>{{ cat.label }}</small>
          </li>
        </ul>
        <div class="card-body" v-if="activeSubtab === 'general'">
          <div class="form-group">
            <div class="form-group">
              <div class="btn-group me-3">
                <input type="radio" value="1" class="btn-check" id="issues_active_frontend-yes" autocomplete="off"
                       v-model.number="config.issues_active_frontend">
                <label class="btn btn-outline-primary" for="issues_active_frontend-yes">Ja</label>
                <input type="radio" value="0" class="btn-check" id="issues_active_frontend-no" autocomplete="off"
                       v-model.number="config.issues_active_frontend">
                <label class="btn btn-outline-primary" for="issues_active_frontend-no">Nein</label>
              </div>
              <label>Ticket System verwenden</label>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">

                <div class="btn-group me-3">
                  <input type="radio" value="1" class="btn-check" id="use_crm_mailing-yes" autocomplete="off"
                         v-model.number="config.use_issue_crm_mailing">
                  <label class="btn btn-outline-primary" for="use_crm_mailing-yes">{{ $t("globals.yes") }}</label>
                  <input type="radio" value="0" class="btn-check" id="use_crm_mailing-no" autocomplete="off"
                         v-model.number="config.use_issue_crm_mailing">
                  <label class="btn btn-outline-primary" for="use_crm_mailing-no">{{ $t("globals.no") }}</label>

                </div>
                <label>Seperates Mailing für Ticketsystem</label>
              </div>
            </div>
            <div class="col-12" v-if="config.use_issue_crm_mailing === '1'">
              <div class="form-group">
                <label for="mailfrom">{{$t("globalSetting.emails.mailform")}}</label>
                <input id="mailfrom" v-model="config.mailfrom" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12" v-if="config.use_issue_crm_mailing === '1'">
              <div class="form-group">
                <label for="fromname">{{$t("globalSetting.emails.formname")}}</label>
                <input id="fromname" v-model="config.issue_fromname" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12" v-if="config.use_issue_crm_mailing === '1'">
              <div class="form-group">
                <label for="smtphost">{{$t("globalSetting.emails.smtphost")}}</label>
                <input id="smtphost" v-model="config.issue_smtphost" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12" v-if="config.use_issue_crm_mailing === '1'">
              <div class="form-group">
                <label for="smtpport">{{$t("globalSetting.emails.smtpport")}}</label>
                <input id="smtpport" v-model="config.issue_smtpport" type="text" class="form-control"/>
              </div>
            </div>
            <!-- And so on until 'email_signature' -->
            <div class="col-12"  v-if="config.use_issue_crm_mailing === '1'" >
              <div class="form-group">
                <label for="email_logo">{{$t("globalSetting.emails.emailLogo")}}</label>
                <input id="email_logo" v-model="config.issue_email_logo" type="text" class="form-control"/>
              </div>
            </div>
            <div class="col-12"  v-if="config.use_issue_crm_mailing === '1'" >
              <div class="form-group">
                <label for="email_signature">{{$t("globalSetting.emails.emailSignature")}}</label>
                <Editor2 id="email_signature" v-model="config.issue_email_signature" class="form-control"></Editor2>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Editor2 from "@/components/Editor2";
import axios   from "axios";
import IndexedDbFunctions from "@/assets/js/indexedDbFunctions";

export default {
  name: "GlobalConfig",
  data() {
    return {
      activeSubtab: 'general',
      configTabs: [
        {name: 'general', label: "Global", icon: 'fa-cog'},

      ],
      config: {}
    }
  },
  components: {
    Editor2
  },
  methods: {
    getConfigLocal() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let getUrl = this.$store.getters.getApiUrl + "config/params/values";

      getUrl = getUrl + "?limit=0";
      axios.get(getUrl, {headers}).then(async (response) => {
        let shortResponse = response.data.result;
        let key = 'params_values';

        await IndexedDbFunctions.addObject(this.$store.state.dbName, this.$store.state.dbVersion, 'config', {
          id: key,
          data: shortResponse.data
        });
        this.config = shortResponse.data;
      }).catch(function () {
      });
    },
    changeTab(tab) {
      this.activeSubtab = tab;
    },
    saveConfig(){
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.put(this.$store.state.apiUrl + "config/params", this.config, {headers}).then((r) => {
          let key = 'params_values';
          VueInner.$store.dispatch('toastSuccess', 'Einstellung wurde erfolgreich gespeichert');
          IndexedDbFunctions.addObject(this.$store.state.dbName, this.$store.state.dbVersion, 'config', {
            id: key,
            data: r.data.result.data
          });
        this.config = r.data.result.data;
          this.$router.push({name: 'ConfigWorkflowGlobal'});
    }).catch((error) => {
      this.$store.dispatch("handleApiResponse", error.response);
          this.$router.push({name: 'ConfigWorkflowGlobal'});
    });


    }
  },

  beforeMount() {


   this.getConfigLocal();

  }
}
</script>
<style scoped>
</style>
