<template>
  <div class="btn-group me-3 ms-3" v-if="((taskActive && taskActive.id) || (getTimer && getTimer.task && getTimer.task.id))">
    <template v-if="!getTimer.task">
      <button @click="this.$store.commit('timerStart', taskActive)" class="btn btn-outline-primary purple btn-sm" tooltip="Task: Timer starten" position="left">
        <i class="fa-duotone fa-stopwatch"></i>
      </button>
    </template>
    <template v-else>
      <template v-if="getTimer.paused">
        <div class="btn btn-outline-secondary btn-sm" position="bottom" :tooltip="'#'+getTimer.task.id+': '+getTimer.task.title">{{ getTimer.formatted }}</div>
      </template>
      <template v-else>
      <div class="btn btn-outline-primary btn-sm" position="bottom" :tooltip="'#'+getTimer.task.id+': '+getTimer.task.title">{{ getTimer.formatted }}</div>
      </template>
	    <button v-if="getTimer.paused" @click="$store.commit('timerStart', getTimer.task)" class="btn btn-outline-secondary btn-sm" tooltip="Timer fortsetzen" position="bottom">
		    <i :class="getTimer.paused ? 'fa-duotone fa-play-circle' : 'fa-duotone fa-pause-circle'"></i>
	    </button>
      <button v-else @click="$store.commit('timerPause')" class="btn btn-outline-secondary btn-sm" tooltip="Timer pausieren" position="bottom">
        <i :class="getTimer.paused ? 'fa-duotone fa-play-circle' : 'fa-duotone fa-pause-circle'"></i>
      </button>
      <button @click="openPopupTimer()" class="btn btn-outline-primary btn-sm" tooltip="Timer stoppen" position="bottom">
        <i class="fa-duotone fa-stop-circle"></i>
      </button>
    </template>
    <Modal v-if="getTimer && openTime">

    <div class="modal card show " style="display: block;">

	    <select v-model="message.activity_id" class="form-select">
		    <option value="">Bitte wählen</option>
		    <option v-for="activity in getConfigs.issue_activities" :value="activity.id">{{ activity.title }}</option>
	    </select>
	      <textarea v-model="message.comment" />


        <button v-if="message.activity_id && message.comment" class="btn" @click="$store.dispatch('timerEnd', message); openTime = false;">SENDEN</button>
    </div>
    </Modal>

  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  name: "Timer",
  data() {
    return {
		openTime:false,
     message: {
		   activity_id: null,
       comment: '',
     }
    };
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getConfigs", "getTimer"]),

  },
  methods: {
	  openPopupTimer() {
      this.openTime = true;
    },
  }
};
</script>

<style scoped>
</style>
