<template>
  <div class="appView greenView">
  <div class="container-fluid ">
    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4 col-12">
                <div class="form-group">
                  <label for="employee">Mitarbeiter</label>

                  <select id="employee" class="form-select small" v-model="filter_owner_id" v-if="getEmployees"  @change="filter">
                    <option value="">Mitarbeiter wählen</option>
                    <option v-for="employee in getEmployees" :value="employee.id" v-bind:key="employee.id">
                      {{ employee.e_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4 col-12">
                <div class="form-group">
                  <label for="month">Monat</label>
                  <select id="month" class="form-select small" v-model="filter_month" @change="filter">
                    <option value="">Monat wählen</option>
                    <option value="01">Januar</option>
                    <option value="02">Februar</option>
                    <option value="03">März</option>
                    <option value="04">April</option>
                    <option value="05">Mai</option>
                    <option value="06">Juni</option>
                    <option value="07">Juli</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">Oktober</option>
                    <option value="11">November</option>
                    <option value="12">Dezember</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4 col-12">
                <div class="form-group">
                  <label for="year">Jahr</label>
                  <select id="year" class="form-select small" v-model="filter_year"  @change="filter">
                    <option value="">Jahr wählen</option>
                    <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                  </select>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table class="table table-hover table-sm">
              <thead>
              <tr>
                <th>Tag</th>
                <th>Summe</th>
                <th>Grund</th>
                <th>Mitarbeiter</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="currentTimes.length  > 0 " v-for="currentTime in currentTimes" v-bind:key="currentTime.id">
                <td>{{ $filters.formatDate(currentTime.day) }}</td>
                <td>{{ currentTime.sum }}</td>
                <td>{{ currentTime.reason }}</td>
                <td>{{currentTime.e_name}}</td>
              </tr>
              <tr v-else><td colspan="100%"><p>Kein Eintrag gefunden.</p></td></tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div></div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'TimesOverview',
  data() {
    return {
      employees: {},
      currentTimes: {},
      selectedEmployee: null,
      selectedMonth: null,
      selectedYear: null,
      filter_query: '',
      filter_owner_id: '',
      filter_month: '',
      filter_year: '',
    }
  },
  components: {

  },

  methods: {
    getLocalEmployees() {

        this.employees = this.getEmployees;

    },
    setDefaults(){
      if(this.getUser && this.getUser._isEmployee){
        this.filter_owner_id = this.getUser._isEmployee;
      }
      // get Current month like this: 01, 02, 03, ...
      let date = new Date();
      this.filter_month = ("0" + (date.getMonth() + 1)).slice(-2);
      this.filter_year = date.getFullYear();

    },
    filter() {
      this.filter_query = '';
      if (this.filter_owner_id) {
        this.filter_query += '?filter_owner=' + this.filter_owner_id;
      }
      if (this.filter_month) {
        this.filter_query += '&filter_month=' + this.filter_month;
      }
      if (this.filter_year) {
        this.filter_query += '&filter_year=' + this.filter_year;
      }
      this.getCurrentTimes();
    },
    getCurrentTimes() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "time/search" + this.filter_query, {headers}).then((r) => {
        VueInner.currentTimes = r.data.result.data;
      });
    }
  },
  created() {
    this.getLocalEmployees();
    this.setDefaults();
    this.filter();

  },
  computed: {
    ...mapGetters(["getPlugins",  "getUser", "getEmployees"]),
    years() {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2015}, (value, index) => 2016 + index)
    },

  },

}
</script>
