<template>
  <div class="addNewCommentWrap">
    <div class="row">
      <div class="col-xl-1 col-12">
        <p class="text-end pt-3">
          <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, getUser._isEmployee, 'id')"
               class="sketchAvatar">
        </p>
      </div>
      <div class="col-xl-11 col-12">
        <div class="commentWrap mt-3">
          <Editor2 v-model="comment.comment"></Editor2>
          <button @click="postComment(false)" class="btn btn-outline-primary me-3"><i class="fa-duotone fa-paper-plane"></i>
            Speichern
          </button>
          <button @click="postComment(true)" v-if="comment.official === 'official'" class="btn btn-outline-primary me-3"><i class="fa-duotone fa-envelope"></i> Speichern & Kunde informieren
          </button>
          <button @click="$emit('close');" class="btn btn-outline-secondary"><i class="fa-duotone fa-x"></i>
            Abbrechen
          </button>
          <div class="btn-group ms-3 float-end">
            <input type="radio" class="btn-check" id="radio-2"  value="official" v-model="comment.official">
            <label for="radio-2" class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-comments"></i> Kommentar</label>
            <input type="radio" class="btn-check" id="radio-1"  value="not_official" v-model="comment.official">
            <label for="radio-1" class="btn btn-outline-secondary btn-sm"><i class="fa-duotone fa-note-sticky"></i> Interne Notiz</label>

          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";
import {mapGetters} from "vuex";

export default {
  name: "Createcomment",
  emits: ['close'],
  props: {
    taskId: ""
  },
  components: {
    Editor2
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees", "modalsList", "taskActive", "getUser"]),
  },
  data() {
    return {
      comment: {
        task_id: this.taskId,
        comment: "",
        official: 'official'
      },
      taskItem: '',
      showCommentNew: false,
    };
  },
  methods: {
    postComment(sendToCustomer) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {
        data: this.comment,
        sendToCustomer: sendToCustomer
      }
      axios.post(this.$store.state.apiUrl + "issue/" + this.taskId + "/comment", postContent, {headers: headers})
        .then((response) => {
          this.taskItem = response.data.result.data;
          this.$store.dispatch('setTask', this.taskItem);
          this.clearComment();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearComment() {
      this.comment = {
        task_id: this.taskId,
        comment: "",
      };
    }
  }
}
</script>
