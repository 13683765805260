<template>
  <div class="row">
    <div class="col-xl-12 col-12">
      <div class="card">
        <h6 class="card-header">{{$t("contact.customerDetails")}}
          <div class="btn-group float-end">
          <button @click="$store.commit('openModal', 'address');"
                  type="button" class="btn btn-sm btn-outline-primary">
            <i class="fa-duotone fa-plus"></i> {{$t("contact.newContact")}}
          </button>
          <button @click="$router.push({name:'CustomersTabs', params:{id:customerActive.id,tab:'edit'}})"
                  type="button" class="btn btn-sm btn-outline-primary"
                  >
            <i class="fa-duotone fa-pencil"></i> {{$t("contact.edit")}}
          </button></div>
        </h6>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-xxl-3 mainContact">
              <p class="mb-0 font-weight-bold text-sm"><strong>{{ customerActive.c_company }}</strong></p>
              <p class="mb-0 text-sm">{{ customerActive.c_title }} {{ customerActive.c_suffix }} {{ customerActive.c_firstname }} {{ customerActive.c_lastname }}</p>
              <p class="mb-0 text-sm">{{ customerActive.c_street }}</p>
              <p class="mb-0 text-sm">{{ customerActive.c_zipcode }} {{ customerActive.c_city }} {{ customerActive.localCountry }}</p><br>
              <p class="mb-0 text-sm"><span tooltip="Branche" position="right"><i class="fa-duotone fa-industry"></i> {{(customerActive.c_branch)}}</span></p>
              <p class="mb-0 text-sm"><span tooltip="Unternehmensform" position="right"><i class="fa-duotone fa-scale-balanced"></i> {{(customerActive.c_business)}}</span></p>
              <p class="mb-0 text-sm"><span tooltip="Firmen Funktion" position="right"><i class="fa-duotone fa-briefcase"></i> {{(customerActive.funktion)}}</span></p><br>
              <p class="mb-0 text-sm">{{customerActive.c_gender}}<span tooltip="Geschlecht" position="right"><i class="fa-duotone fa-venus-mars"></i></span></p>
              <p class="mb-0 text-sm" v-if="customerActive.c_birthday && customerActive.c_birthday !== '0000-00-00'" ><span tooltip="Geburtstag (Alter)" position="right"><i class="fa-duotone fa-birthday-cake"></i> {{$filters.formatDate(customerActive.c_birthday)}} ({{renderAge(customerActive.c_birthday)}})</span></p>
              <p class="mb-0 text-sm">{{customerActive.birthday_city}}<span tooltip="Geburtsort" position="right"><i class="fa-duotone fa-birthday-cake"></i></span></p>
            </div>
            <div class="col-12 col-xxl-3  mainContact mb-2">
              <div class="btn-group" v-if="customerActive.c_website">
                <button class="copyClip"  v-if="customerActive.c_website"
                        @click="$store.dispatch ('copyToClipboard',customerActive.c_website)">
                  <i class="fa-duotone fa-earth-europe" ></i>
                  {{ customerActive.c_website }}
                </button><span :tooltip="$t('customer.openexternal')" position="right"><a :href="'//'+customerActive.c_website" target="_blank" class="text-sm"
                                                                                         v-if="customerActive.c_website"><i class="fa-duotone fa-arrow-up-right-from-square" ></i></a></span>
              </div>
              <div class="btn-group" v-if="customerActive.c_phone" tooltip="Telefon" position="right">
                <button class="copyClip" v-if="customerActive.c_phone"
                        @click="$store.dispatch('copyToClipboard',customerActive.c_phone)"><i class="fa-duotone fa-phone"></i> {{ customerActive.c_phone }}
                </button>
                <callnow :phone="customerActive.c_phone" />
              </div>
              <div class="btn-group" v-if="customerActive.c_phone2" tooltip="Telefon Geschäftlich" position="right">
                <button class="copyClip" v-if="customerActive.c_phone2"
                        @click="$store.dispatch('copyToClipboard',customerActive.c_phone2)"><i class="fa-duotone fa-phone-office"></i> {{ customerActive.c_phone2 }}
                </button>
                <callnow :phone="customerActive.c_phone2" />
              </div>
              <div class="btn-group" v-if="customerActive.c_handy" tooltip="Mobil" position="right">
                <button class="copyClip" v-if="customerActive.c_handy"
                        @click="$store.dispatch('copyToClipboard',customerActive.c_handy)"><i
                  class="fa-duotone fa-mobile"></i> {{ customerActive.c_handy }}
                </button>
                <callnow :phone="customerActive.c_handy" />
              </div>
              <div class="btn-group" v-if="customerActive.c_fax" tooltip="Fax" position="right">
                <button class="copyClip" v-if="customerActive.c_fax"
                        @click="$store.dispatch('copyToClipboard',customerActive.c_fax)"><i
                  class="fa-duotone fa-fax"></i> {{ customerActive.c_fax }}
                </button>
                <callnow :phone="customerActive.c_handy" />
              </div>
              <div class="btn-group" v-if="customerActive.c_email" tooltip="E-Mail" position="right">
              <button class="copyClip" @click="$store.dispatch('copyToClipboard',customerActive.c_email)"
                      v-if="customerActive.c_email"><i
                class="fa-duotone fa-envelope"></i>
                {{ customerActive.c_email }}
              </button>
              </div>
            </div>
            <div class="col-12 col-xxl-4">
              <template v-if="addressList">
                <p class="empty-list" v-if="!addressList.data || !Object.keys(addressList.data).length">Keine Kontakte vorhanden</p>
                <div class="addresswrap">

                  <perfect-scrollbar style="max-height: 365px;">
                    <div class="entry" v-for="address in addressList.data">
                      <div class="btn-group-vertical float-end">
                      <button @click="$store.commit('openModal', 'address');$store.commit('setAddress', address);" :tooltip="(address.c_firstname && address.c_lastname) ? address.c_firstname+' '+address.c_lastname+' '+this.$t('contact.editSmall') : this.$t('contact.editSmall')" :position="'left'"
                              type="button" class="btn btn-xs btn-outline-primary" style="margin-bottom: 0; transform: none;">
                        <i class="fa-duotone fa-edit"></i>
                      </button>
                      <button class="btn btn-outline-danger btn-xs"
                              :tooltip="(address.c_firstname && address.c_lastname) ? address.c_firstname+' '+address.c_lastname+' '+this.$t('contact.delete') : this.$t('contact.delete')" :position="'left'"
                              @click="deleteAddress(address.id)"><i class="fa-duotone fa-trash"></i></button></div>
                      <p class="mb-0 text-sm"><i class="fa-duotone fa-user"></i> {{address.c_title}} {{ address.c_firstname }} {{ address.c_lastname }}</p>
                      <p class="mb-0 text-sm" v-if="address.funktion"><i class="fa-duotone fa-user-astronaut"></i> {{address.funktion}}</p>
                      <p class="mb-0 text-sm" v-if="address.c_street">{{ address.c_street }}</p>
                      <p class="mb-0 text-sm" v-if="address.c_zipcode || address.c_city">{{ address.c_zipcode }} {{ address.c_city }}</p>
                      <div class="btn-group" v-if="address.c_phone">
                      <button class="copyClip c_phone" v-if="address.c_phone"
                              @click="$store.dispatch('copyToClipboard',address.c_phone)"><i
                        class="fa-duotone fa-phone"></i> {{ address.c_phone }}
                      </button>
                      <callnow :phone="address.c_phone"  />
                      </div>
                      <div class="btn-group">
                      <button class="copyClip a " v-if="address.c_handy"
                              @click="$store.dispatch('copyToClipboard',address.c_handy)"><i
                        class="fa-duotone fa-mobile"></i> {{ address.c_handy }}
                      </button>
                        <callnow :phone="address.c_handy" />
                      </div>
                      <button class="copyClip bn" @click="$store.dispatch('copyToClipboard',address.c_email)"
                              v-if="address.c_email"><i class="fa-duotone fa-envelope"></i>
                        {{ address.c_email }}
                      </button>
                    </div>
                  </perfect-scrollbar>
                </div>
              </template>
            </div>
            <div class="col-12 col-xxl-2">
              <div class="row d-flex">
                <div class="col-12  justify-content-center align-self-center">
                  <p class="mb-0 font-weight-bold text-sm color">{{$t("contact.supervisor")}}</p>
                  <p class="mb-0 text-sm"><img :src="$filters.employeeIcon(getEmployees, customerActive.c_owner,'id')"
                                               class="sketchAvatar">
                    {{ $filters.employeeName(getEmployees, customerActive.c_owner, 'id') }}</p>
                </div>
                <div class="col-12 mt-2  justify-content-center align-self-center " v-if="customerActive.profile_image">
                  <p class="mb-0 font-weight-bold text-sm color">Ansprechpartner</p>
                  <img :src="'data:'+customerActive.profile_mimetype+';base64,'+customerActive.profile_image" class="sketchAvatar" /> {{customerActive.c_firstname}} {{customerActive.c_lastname}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
    </div>
  </div>
  <Modal v-if="modalsList.address">
    <CreateContact/>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import CreateContact from "./CreateContact";
import callnow from "@/routing/plugins/callnow.vue";
export default {
  name: "CustomerContact",
  computed: {
    ...mapGetters(["getEmployees", "customerActive", "modalsList", "addressList", "getPlugins", "getUser"]),
  },
  props: {
    customer: {}
  },
  components: {
    CreateContact, callnow
  },
  methods: {
    getAddresses() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/addresses", {headers}).then((r) => {
        this.$store.commit("setAddresses", {data: r.data.result.data, customer_id: this.customerActive.id});
      });
    },
    renderAge(birthday) {
      let today = new Date();
      let birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    deleteAddress(id) {

      this.$confirm( this.$t("contact.deleteEntry"), this.$t("contact.deleteAddress"), {
        confirmButtonText: this.$t("globals.yes"),
        cancelButtonText: this.$t("globals.no"),
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "addresses/" + id, {headers}).then((r) => {
          this.$store.commit("setAddresses", {data: r.data.result.data, customer_id: this.customerActive.id});
          this.$store.dispatch("toastSuccess", this.$t("toast.success.deleteSuccess"));
        });
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
      });
    },
  },
  created() {
    this.getAddresses();
  },
  watch: {
    customerActive: function () {
      this.getAddresses();
    }
  },
  data() {
    return {
      address: {},
      addresses: [],
      createContact: false
    };
  },
};
</script>
