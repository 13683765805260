<template>
  <div class="card">
    <template v-if="!activeRequest">
      <div class="card-header">
        <h6 style="display: inline;">Bewerbungen</h6>
        <p style="padding-left:24px; display: inline;" v-if="requests && requests.length">{{ requests.length }} gesamt
          davon
          {{ filteredRequests.length }} gefiltert</p>
        <div style="display: inline-block; width: 200px;margin-left: 24px; margin-bottom: 0;" class="input-group-sm">
          <input style="margin-bottom: 0;" type="text" class="form-control" @keyup="filterRequests()"
                 v-model="search.phrase"
                 placeholder="Nachname suchen...">
        </div>
        <div class="btn-group float-end">
          <button class="btn btn-outline-primary btn-sm" @click="getExport()" :tooltip="'CSV exportieren'"
                  position="left">
            <i class="fa-duotone fa-file-export"></i>
          </button>
          <button class="btn btn-outline-primary btn-sm" @click="getExportPDF(false)" position="left" tooltip="PDF
          Exportieren"><i class="fa-duotone fa-file-pdf"></i>
          </button>
          <button class="btn btn-outline-secondary btn-sm" @click="getExportPDF(true)" position="left" tooltip="Erweiterte PDF
          Exportieren"><i class="fa-duotone fa-file-pdf"></i>
          </button>
          <button class="btn btn-outline-danger btn-sm" tooltip="Auswahl auf ungeprüft zurückgewiesen!" position="left"
                  @click="moveList('declined_04')"><i class="fa-duotone fa-cancel"></i>
          </button>
          <button class="btn btn-outline-danger btn-sm"
                  @click="showMessage = !showMessage" tooltip="Massenmail" position="left"><i
            class="fa-duotone fa-envelopes-bulk"></i>
          </button>
        </div>
      </div>
    </template>
    <div class="card-body" v-if="!activeRequest">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-secondary me-1 smallerBtn" :class="{'btn-success': (search.state === '')}"
                  @click="search.state = '';filterRequests()">{{ $t("plugins.dfb.status.anyStatus") }}
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'active')}"
                  @click="search.state = 'active';filterRequests()">{{ $t("plugins.dfb.status.active") }}
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'progress')}"
                  @click="search.state = 'progress';filterRequests()">{{ $t("plugins.dfb.status.progress") }}
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'checking')}"
                  @click="search.state = 'checking';filterRequests()">{{ $t("plugins.dfb.status.checking") }}
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'accepted')}"
                  @click="search.state = 'accepted';filterRequests()">{{ $t("plugins.dfb.status.accepted") }}
          </button>

          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined')}"
                  @click="search.state = 'declined';filterRequests()">{{ $t("plugins.dfb.status.declined") }}
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_02')}"
                  @click="search.state = 'declined_02';filterRequests()">{{ $t("plugins.dfb.status.declined_02") }}
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_03')}"
                  @click="search.state = 'declined_03';filterRequests()">{{ $t("plugins.dfb.status.declined_03") }}
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_04')}"
                  @click="search.state = 'declined_04';filterRequests()">{{ $t("plugins.dfb.status.declined_04") }}
          </button>
          <button class="btn btn-outline-secondary me-1 smallerBtn"
                  :class="{'btn-success': (search.state === 'declined_user')}"
                  @click="search.state = 'declined_user';filterRequests()">{{ $t("plugins.dfb.status.declined_user") }}
          </button>
          <template v-if="activeLicense && activeLicense.choosePrepare && activeLicense.choosePrepare.chooseable">
            <button class="btn btn-outline-secondary me-1 smallerBtn" v-for="(choose, key) in activeLicense.choosePrepare.chooseTask"
                    :class="{'btn-success': (search.state === 'prio_'+(parseInt(key)+1))}" :id=" 'prio_'+(parseInt(key)+1)"
                    @click="search.state = 'prio_'+(parseInt(key)+1);filterRequests()">{{choose.title}}
            </button>

          </template>
        </div>
      </div>


      <hr>
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-secondary me-3 smallerBtn" :class="{'btn-success': (search.type === '')}"
                  @click="search.type = '';filterRequests()">Jede Lizenz
          </button>
          <select name="" id="" class="small" style="float:right;" v-model="filterSelected" @change="filterSelect()">
            <option value="">- Archiv -</option>
            <template :key="ty.id" v-for="ty in requestTypes">
              <option v-if="ty.state === 'archived' || ty.state === 'prepare'" :value="ty">
                {{ ty.name }} {{ $filters.formatDate(ty.published_to) }}
              </option>
            </template>
          </select>
          <template :key="ty.id" v-for="ty in requestTypes">

            <button v-if="ty.state !== 'archived' && ty.state !== 'prepare'"
                    class="btn btn-outline-secondary me-3 smallerBtn"
                    :class="{'btn-success': (search.type === ty.id)}"
                    @click="search.type = ty.id; activeLicense = ty; filterRequests()">
              {{ ty.name }} {{ $filters.formatDate(ty.published_to) }}
            </button>
          </template>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-12">
          <button class="btn btn-outline-secondary me-3 smallerBtn"
                  :class="{'btn-success': (search.international === false && search.aufsteiger === false && search.doppel === false)}"
                  @click="search.international = false;search.aufsteiger = false;search.doppel = false;filterRequests()">Alle
          </button>
          <button class="btn btn-outline-secondary me-3 smallerBtn"
                  :class="{'btn-success': (search.international === true)}"
                  @click="search.international = true;filterRequests()">Internationale
          </button>
          <button class="btn btn-outline-secondary me-3 smallerBtn"
                  :class="{'btn-success': (search.aufsteiger === true)}"
                  @click="search.aufsteiger = true;filterRequests()">Aufsteiger
          </button>
          <button class="btn btn-outline-secondary me-3 smallerBtn"
                  :class="{'btn-success': (search.doppel === true)}"
                  @click="search.doppel = true;filterRequests()">Doppelbewerber
          </button>

        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <Loading :loading="loadingRequests" v-if="loadingRequests"></Loading>
          <div class="singleRequest" v-else v-for="(request,inde) in filteredRequests" :key="request.id">
            <div class="pluginWrap" v-if="request.name">
              <div class="plugin-item">
                <div class="lineitem l-number" :ref="'request'+request.id">
                  <span class="indexer w-100" style="font-size: 15px; font-weight: bold; ">{{ inde + 1 }} </span>
                </div>
                <div class="lineitem l-title"><strong>{{ request.c_title }} {{ request.c_firstname }}
                  {{ request.c_lastname }}</strong>
                  <!--                  <p v-if="request.custom_fields" class="text-sm text-secondary mb-4">{{request.custom_fields.mannschaft}}<br>{{request.custom_fields.liga}}<br>{{request.custom_fields.altersbereich}}</p>-->
                </div>
                <div class="lineitem l-prio" >
                  <div style="display: none;">{{request}}</div>
                  <template v-if="request.published_from && request.published_to">
                    <span class="roundBadge" :class="renderGradeClass(request.name)"
                          :tooltip="$filters.formatDate(request.published_from)+' - '+$filters.formatDate(request.published_to)" :style="'background-color:'+request.color+';'"
                          position="right"  >{{ renderGradeName(request.name) }}</span></template>
                  <template v-else><span class="roundBadge" v-if="request.name"  :class="renderGradeClass(request.name)" :style="'background-color:'+request.color+';'" :tooltip="request.name">{{ request.name.substring(0, 2) }}</span>
                  </template>
                </div>
                <div class="lineitem l-type"><span v-html="renderProgress(request.state)"></span>
                </div>
                <div class="lineitem l-infos">
                  <span class="badge badge-secondary w-100" v-if="request.gender">{{ request.gender }}</span>
                  <span class="badge badge-outline w-100" v-else>Kein Geschlecht</span>


                </div>
                <div class="lineitem l-infos">
                  <span v-if="request.international" class="badge bg-outline-primary badge-secondary w-100 ">International</span>
                  <span v-else class="badge badge-outline badge-secondary w-100 ">International</span>
                </div>
                <div class="line-item l-infos me-1">
                  <span v-if="request.custom_fields && request.custom_fields.aufstieg" class="badge badge-secondary w-100">Aufstieg</span>
                  <span v-else class="badge badge-outline w-100">Aufstieg</span>
                </div>
                <div class="line-item l-infos">
                  <span v-if="request.doppel" class="badge badge-secondary w-100">Doppelbewerber</span>
                  <span v-else class="badge badge-outline w-100">Doppelbewerber</span>
                </div>
                <div class="lineitem ml-auto">
                  <p class="text-sm text-secondary mb-0" tooltip="Punkte">{{ request.finalPoints }}</p>
                </div>
                <div class="lineitem l-action ">
                  <button @click="activeRequest = request.id" class="btn btn-outline-primary btn-xs w-100"><i class="fa-duotone fa-edit"></i>
                  </button>
                </div>
              </div>
            </div>
            <!--
            TODO-Render request<br>
            {{ request }}
            <button @click="activeRequest = request" class="btn">Öffnen</button>
            -->
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <button @click="lastRequest = activeRequest; activeRequest = null; getRequests()" class="btn btn-outline-danger btn-sm"
              style="position: absolute; top: 24px; right: 24px;"><i class="fa-duotone fa-close"></i> Zurück
      </button>
      <ActiveRequest :activeRequest="activeRequest"/>
    </div>
  </div>
  <Modal v-if="showMessage">
    <div class="boxGrey">
      <h6>Notiz, verfassen</h6>
      <select style="width: 100%; height: 30px; margin-bottom: 24px;"
              v-model="activeDraft"
      >
        <option :value="''">- Aus Vorlage wählen -</option>
        <optgroup :label="tit" v-for="(dr,tit) in activeDrafts">
          <option :value="draft" v-for="draft in dr" :key="draft.id">{{ draft.title }}</option>
        </optgroup>
      </select>
      <input class="w-100 mb-2" type="text" v-model="headline">
      <Editor2 v-model="comment"/>
      <input class="w-100 mb-2 mt-2" type="text" placeholder="BCC kommasepariert" v-model="bcc">
      <input type="file" style="width: 100%; margin-top: 24px;margin-bottom: 24px;" ref="file" @change="readFile()">
      <div class="mb-4">
        <p>Diese Nachricht wird an {{ filteredRequests.length }} Kunden gesendet!</p>
      </div>
      <button class="mt-4 me-3 btn btn-primary" v-if="!sendingMails" @click="sendBatchMail();">SENDEN</button>
      <span v-else>Bitte warten!</span>
      <button class="mt-4 me-3 btn btn-danger" @click="showMessage = false">Abbrechen</button>
    </div>
  </Modal>
</template>
<script>
import ActiveRequest from './ActiveRequest'
import axios from "axios";
import {ref} from "@vue/runtime-dom";
import Editor2 from "@/components/Editor2.vue";
import Loading from "@/components/Loading"
export default {
  name: "DFBRequest",
  components: {
    Editor2,
    ActiveRequest,
    Loading
  },
  setup() {
    const isShow = ref(false)

    function showModal() {
      isShow.value = true
    }

    function closeModal() {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },
  methods: {
    sendBatchMail() {
      if (confirm("Wirklich an die aktuelle Auswahl senden?") === true) {
        this.sendingMails = true;
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.put(this.$store.state.apiUrl + "plugins/dfb/sendBatchMail/", {
          data: this.filteredRequests,
          comment: this.comment,
          headline: this.headline,
          attachment: this.activeFile,
          filename: this.activeFileName,
          bcc:this.bcc,
        }, {headers}).then(() => {
          this.showMessage = false;
          this.comment = '';
          this.activeFile = '';
          this.headline = '';
          this.sendingMails = false;
        });
      } else {
        return false;
      }
    },
    scrollTo(selector) {
      if (this.$refs[selector] && this.$refs[selector][0]) {
        this.$refs[selector][0].scrollIntoView({behavior: "smooth"});
      }
    },
    // filter requests by request.c_lastname
    filterRequests: function () {
      this.filteredRequests = this.requests;
      if (this.search.phrase !== '') {
        this.filteredRequests = this.filteredRequests.filter(request => request.c_lastname.toLowerCase().includes(this.search.phrase.toLowerCase()))
      }
      if (this.search.type !== '') {
        this.filteredRequests = this.filteredRequests.filter(request => request.request_id === this.search.type)
      }
      if (this.search.state !== '') {
        this.filteredRequests = this.filteredRequests.filter(request => request.state === this.search.state)
      }
      if (this.search.international) {
        this.filteredRequests = this.filteredRequests.filter(request => request.international === true)
      }
      if (this.search.aufsteiger) {
        this.filteredRequests = this.filteredRequests.filter(request => request.custom_fields && request.custom_fields.aufstieg === true)
      }
      if (this.search.doppel) {
        this.filteredRequests = this.filteredRequests.filter(request => request.doppel === true)
      }
      if (this.lastRequest) {
        this.scrollTo('request' + this.lastRequest);
        this.lastRequest = null;
      }
    },
    sortRequests: function (a, b) {
      if (a.c_lastname < b.c_lastname) {
        return -1;
      }
      if (a.c_lastname > b.c_lastname) {
        return 1;
      }
      return 0;
    },
    getRequestTypes() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requestTypes", {headers}).then((r) => {
        this.requestTypes = r.data.result.data;
        this.loading = false;
      });
    },
    getRequests() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/requests", {headers}).then((r) => {
        this.requests = r.data.result.data;
        this.filterRequests();
        this.loadingRequests = false;
      });
    },
    renderGradeName(name){
      if(name ==='Torwart A Lizenz'){
        return 'TA';
      }
      if(name ==='Torwart B Lizenz'){
        return 'TB';
      }
      if(name ==='Torwart Leistungskurs'){
        return 'TL';
      }
      return name.substring(0, 2)
    },
    renderGradeClass(name){



      if (!name) {
        return '';
      }
      const modifiedName = name

        .replace(/\+/g, "plus") // Replace + with "plus"
        .replace(/[^\w\s]/gi, "") // Remove special characters
        .replace(/\s/g, ""); // Remove spaces

      if (!modifiedName) {
        return '';
      }
      return modifiedName.substring(0, 4);
    },
    renderProgress(progress) {
      let returnProgress = '';
      returnProgress = this.renderState[progress];
      if(returnProgress === undefined) returnProgress = this.renderState['default'];
      let returnHtml = '<span tooltip="'+returnProgress.text+'" position="right"><i class="'+returnProgress.icon+'" style="color:'+returnProgress.color+'"></i></span>';
      return returnHtml;
    },
    moveList($state) {
      // get Export from API
      // javascript confirm
      if (confirm("Sie verschieben damit die aktuelle Auswahl!") === true) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.put(this.$store.state.apiUrl + "plugins/dfb/moveRequests", {
          data: this.filteredRequests,
          state: $state
        }, {headers}).then(() => {
          this.getRequests();
        });
      } else {
        return false;
      }
    },
    getExport() {
      // get Export from API
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "plugins/dfb/exportRequests", {data: this.filteredRequests}, {headers}).then((r) => {
        let rows = r.data.result.data;
        let csvString = rows.map(e => e.join(";")).join("\n");
        // convert array in utf8
        var universalBOM = "\uFEFF";
        var a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString));
        a.setAttribute('download', 'export.csv');
        window.document.body.appendChild(a);
        a.click();
      });
    }, getExportPDF(extended) {
      if (extended === undefined) {
        extended = false;
      }
      if (this.exportRunning) alert("Download im Gang!");
      this.exportRunning = true;
      // get Export from API
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.post(this.$store.state.apiUrl + "plugins/dfb/exportRequestsPDF", {data: this.filteredRequests, extended: extended}, {headers}).then((r) => {
        // force download zip file from base64
        const linkSource = 'data:application/zip;base64,' + r.data.result.data;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', 'export_bewerbungen.zip');
        window.document.body.appendChild(a);
        a.click();
        this.exportRunning = false;
      });
    },
    getDrafts() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "draft/search?limit=0", {headers}).then((r) => {
        this.drafts = r.data.result.data;
        // sort drafts by group_id in activeDrafts
        this.drafts.forEach((draft) => {
          if (this.activeDrafts[draft.categoryTitle] === undefined) {
            this.activeDrafts[draft.categoryTitle] = [];
          }
          this.activeDrafts[draft.categoryTitle].push(draft);
        });
        this.loading = false;
      });
    },
    readFile() {
      this.file = this.$refs.file.files[0];
      this.$log.debug("reading");
      this.$log.debug(this.file);
      let VueInner = this;
      this.activeFileName = this.file.name;
      if (this.file) {
        var reader = new FileReader();
        reader.onloadend = () => {
          // Use a regex to remove data url part
          const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
          VueInner.activeFile = base64String;
        };
        reader.readAsDataURL(this.file);
      }
    },
    filterSelect() {
      if (this.filterSelected === '') {
        this.search.type = '';
        this.filterRequests();
      } else {
        this.search.type = this.filterSelected.id;
        this.activeLicense = this.filterSelected;
        this.filterRequests();
      }
    }
  },
  data() {
    return {
      lastRequest: null,
      filterSelected: '',
      exportRunning: false,
      activeLicense: '',
      loading: true,
      loadingRequests: true,
      activeDrafts: {},
      search: {
        phrase: '',
        type: '',
        state: '',
        international: false,
        aufsteiger: false,
        doppel: false,
      },
      sendingMails: false,
      showMessage: false,
      requestTypes: [],
      requests: [],
      filteredRequests: [],
      activeRequest: null,
      requestDetails: {},
      drafts: [],
      activeDraft: '',
      comment: '',
      headline: "Es gibt Neuigkeiten zu Ihrer Bewerbung.",
      activeFile: null,
      file: null,
      activeFileName: null,
      renderState: {
        "active": {icon: "fa-duotone fa-edit", color: "#0088CC", text: "Vom Bewerber auszufüllen"},
        "progress": {icon: "fa-duotone fa-clipboard-list-check", color: "#ecaa12", text:"Vom DFB zu bearbeiten"},
        "checking": {icon: "fa-duotone fa-gear", color: "#ecaa12", text:"Vom DFB in Bearbeitung"},
        "accepted": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Vom DFB akzeptiert"},
        "prio_1": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 1"},
        "prio_2": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 2"},
        "prio_3": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 3"},
        "prio_4": {icon: "fa-duotone fa-check", color: "#0088CC", text: "Lehrgang 4"},
        "closed": {icon: "fa-duotone fa-times", color: "#0088CC", text: "Abgelehnt"},
        "declined": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Nicht eingereicht"},
        "declined_01": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Nicht eingereicht"},
        "declined_02": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Voraussetzungen nicht erfüllt"},
        "declined_03": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Zu wenig Punkte"},
        "declined_04": {icon: "fa-duotone fa-circle-xmark", color: "red", text: "Ungeprüft zurückgewiesen"},
        "default": {icon: "fa-duotone fa-circle-xmark", color: "gray", text: "Fallback"},
      },
      bcc: null,
    };
  },
  created() {
    this.getRequests();
    this.getRequestTypes();
    this.getDrafts();
  },
  watch: {
    activeRequest() {
      if (this.activeRequest && this.activeRequest.id) {
        this.getRequestDetails();
      }
    },
    activeDraft() {
      if (this.activeDraft) {
        this.comment = this.activeDraft.draft;
        this.headline = this.activeDraft.title;
      }
    },
    showMessage() {
    }
  }
};
</script>
<style lang="scss" >
.smallerBtn {
  padding: 2px 5px !important;
  font-size: 13px !important;
  font-weight: normal !important;
  border-radius: 3px !important;
  margin-bottom: 4px !important;
}
.l-infos {
  span {
    line-height: 15px;
     @media (min-width: 1600px){
     min-width: 200px;
     }
    &.badge-secondary {
      background: #969696;
      border: 1px solid #969696;
      color: #fff;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.badge-outline {
      border: 1px solid #969696;
      color: #969696;
      background: transparent;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.product-item {
  cursor: pointer;
}

.plugins.dfb .card-body hr {
  margin-top: 8px;
  margin-bottom: 12px;
}
.pluginWrap span.roundBadge{
  background: #969696;
  width: 30px;
  height: 30px;
  line-height: 30px;
}
</style>
