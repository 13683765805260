<template>
  <div class="appView customers">
  <List/>
  </div>
</template>
<script>

import List from "@/routing/core/Customers/List";
import customers from "../../../filter/Customers.vue";

export default {
  name: "Customers",
  computed: {
    customers() {
      return customers
    }
  },
  components: {
    List,
  },
  created: function () {

    this.$store.dispatch("customersFilter");
  },


};
</script>
